import { doSearchCruises } from 'actions/cruisesActions';
import IconCruises from 'app/components/Common/Icons/IconCruises';
import { ItemType, TripType } from 'app/modules/Constants';
import { BasicOption } from 'app/modules/types';
import { default as classNames, default as classnames } from 'classnames';
import { format } from 'date-fns';
import Image from 'next/image';
import { ChangeEvent, PureComponent } from 'react';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import {
	setClosedPriceAlertSignUpModal,
	setPriceAlertEmail,
	setPriceAlertPhone,
	togglePriceAlertSignUpModal,
} from '../../../actions/baseActions';
import { doSearchCars } from '../../../actions/carActions';
import { doSearchFlights } from '../../../actions/flightActions';
import { doSearchHotels } from '../../../actions/hotelActions';
import Helper from '../../modules/Helper';
import { IAirportItem } from '../Common/AirportSelect/Item';
import Checkbox from '../Common/Checkbox';
import FadeTransition from '../Common/FadeTransition';
import PhoneInput from './ModalSignUp/PhoneInput';

type ModalSignUpProps = {
	priceAlertShowModalSignUp: boolean;
	email: string;
	phone: string;
	enabledVert: string;
	hotelDestination: IAirportItem;
	selectedFromLocation: IAirportItem;
	selectedToLocationFlights: IAirportItem;
	selectedFromLocationFlights: IAirportItem;
	selectedFromDate?: Date;
	selectedFromDateFlights?: Date;
	selectedToDateFlights?: Date;
	checkOutDate?: Date;
	checkInDate?: Date;
	selectedFromTime?: string;
	lng: string;
	cruiseDestination: BasicOption;
	cruiseDepartureDate: string[];
	selectedTripType: ItemType;
	setPriceAlertEmail: (email: string) => void;
	setPriceAlertPhone: (phone: string) => void;
	setClosedPriceAlertSignUpModal: (toggle: boolean) => void;
	doSearchFlights: () => void;
	doSearchHotels: () => void;
	doSearchCars: () => void;
	doSearchCruises: () => void;
};
type ModalSignUpState = {
	show: boolean;
	isInvalidEmail: boolean;
	isInvalidPhone: boolean;
	isCheckedNewsLetter: boolean;
};
type DataInfoMethodsType = {
	[key: string]: () => JSX.Element | undefined;
	cars: () => JSX.Element;
	flights: () => JSX.Element | undefined;
	hotels: () => JSX.Element;
	cruises: () => JSX.Element;
};
class ModalSignUp extends PureComponent<ModalSignUpProps, ModalSignUpState> {
	constructor(props: ModalSignUpProps) {
		super(props);
		this.state = {
			show: false,
			isInvalidEmail: false,
			isInvalidPhone: false,
			isCheckedNewsLetter: true,
		};
	}

	componentDidUpdate(prevProps: ModalSignUpProps) {
		if (prevProps.priceAlertShowModalSignUp !== this.props.priceAlertShowModalSignUp) {
			this.setState({
				show: this.props.priceAlertShowModalSignUp,
			});
		}
	}

	handleClose = () => {
		this.setState({
			show: false,
		});
	};

	doSearch = () => {
		if (!Helper.isValidEmail(this.props.email) && this.props.email) {
			this.setState({
				isInvalidEmail: true,
			});
			return;
		}
		this.setState({
			isInvalidEmail: false,
		});
		if (!Helper.isValidPhone(this.props.phone) && this.props.phone) {
			this.setState({
				isInvalidPhone: true,
			});
			return;
		}
		this.setState({
			isInvalidPhone: false,
		});
		this.handleClose();
		this.props.setClosedPriceAlertSignUpModal(true);

		switch (this.props.enabledVert) {
			case 'flights':
				this.props.doSearchFlights();
				break;
			case 'cars':
				this.props.doSearchCars();
				break;
			case 'hotels':
				this.props.doSearchHotels();
				break;
			case 'cruises':
				this.props.doSearchCruises();
				break;
			default:
				break;
		}
	};
	onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		this.props.setPriceAlertEmail(e.target.value);
	};

	onChangePhone = (phone: string) => {
		this.props.setPriceAlertPhone(phone);
	};

	getSelectedLocation = () => {
		if (Helper.isEmpty(this.props.selectedFromLocation) || !this.state.show) {
			return;
		}
		return Helper.getSelectedLocation(this.props.selectedFromLocation, 200, this.props.enabledVert);
	};

	getSelectedLocationHotel = () => {
		if (Helper.isEmpty(this.props.hotelDestination) || !this.state.show) {
			return;
		}
		return Helper.getSelectedLocation(this.props.hotelDestination, 200, this.props.enabledVert);
	};

	getCruiseDestination = () => {
		if (Helper.isEmpty(this.props.cruiseDestination) || !this.state.show) {
			return;
		}
		return this.props.cruiseDestination.label;
	};

	getCarsDataInfo = () => {
		const { selectedFromDate, lng } = this.props;

		return (
			<div className="data-info">
				<div className="location" id="price-alert-location-info">
					{this.getSelectedLocation()}
				</div>
				<div className="date">
					{format(selectedFromDate as Date, 'eee, MMM d, ', { locale: Helper.getLocaleByCode(lng) })}
					{this.props.selectedFromTime}
				</div>
			</div>
		);
	};

	getHotelsDataInfo = () => {
		const { checkInDate, lng } = this.props;

		return (
			<div className="data-info">
				<div className="location" id="price-alert-location-info">
					{this.getSelectedLocationHotel()}
				</div>
				<div className="date">{format(checkInDate as Date, 'eee, MMM d', { locale: Helper.getLocaleByCode(lng) })}</div>
			</div>
		);
	};

	getCruisesDataInfo = () => {
		const { cruiseDepartureDate } = this.props;

		return (
			<div className="data-info">
				<IconCruises className="icon-cruises" />
				<div className="location" id="price-alert-location-info">
					{this.getCruiseDestination()}
				</div>
				<div className="date">
					{cruiseDepartureDate[0]} {cruiseDepartureDate[1] && `- ${cruiseDepartureDate[1]}`}
				</div>
			</div>
		);
	};

	getFlightDataInfo = () => {
		const {
			selectedFromDateFlights,
			lng,
			selectedToLocationFlights,
			selectedToDateFlights,
			selectedFromLocationFlights,
			selectedTripType,
		} = this.props;

		const isOneWay = selectedTripType.value === TripType.ONE_WAY;

		if (Helper.isEmpty(selectedFromLocationFlights) || !this.state.show) {
			return;
		}
		return (
			<div className="data-info flights ">
				<div className="d-flex align-items-center align-content-center">
					<div className="flex-1 text-center">
						<div className="location">{selectedFromLocationFlights.iata}</div>
						{!isOneWay && (
							<div className="date">
								{format(selectedFromDateFlights as Date, 'eee, MMM d', { locale: Helper.getLocaleByCode(lng) })}
							</div>
						)}
					</div>
					<>
						<Image alt="Flights Icon" src="/images/airplane-popup@3x.png" width={50} height={20} />
						<div className="flex-1 text-center">
							<div className="location">{selectedToLocationFlights.iata}</div>
							<div
								className={classNames('date', {
									'pt-6': isOneWay,
								})}
							>
								{!isOneWay && (
									<span>
										{format(selectedToDateFlights as Date, 'eee, MMM d', { locale: Helper.getLocaleByCode(lng) })}
									</span>
								)}
							</div>
						</div>
					</>
				</div>
				{isOneWay && (
					<div className="date text-center">
						{format(selectedFromDateFlights as Date, 'eee, MMM d', { locale: Helper.getLocaleByCode(lng) })}
					</div>
				)}
			</div>
		);
	};

	renderVertIcon = () => {
		switch (this.props.enabledVert) {
			case 'hotels':
				return (
					<div className="vert-icon ">
						<i className="icon-hotels" />
					</div>
				);
			case 'cars':
				return <i className="icon-car-7-outline" />;
			default:
				return '';
		}
	};

	dataInfoMethods: DataInfoMethodsType = {
		cars: this.getCarsDataInfo,
		flights: this.getFlightDataInfo,
		hotels: this.getHotelsDataInfo,
		cruises: this.getCruisesDataInfo,
	};

	render() {
		const { enabledVert, lng } = this.props;
		const isWithPhone = Helper.isSa();
		const isRtl = Helper.isRtlLanguage(lng);
		const phoneInput = isWithPhone ? <PhoneInput onChange={this.onChangePhone} /> : null;

		return (
			<Modal
				backdrop={true}
				id="price-alert-modal-signup"
				size="sm"
				centered
				onEscapeKeyDown={this.handleClose}
				onHide={this.doSearch}
				show={this.state.show}
				className={`${enabledVert} ${isRtl ? 'rtl' : ''}`}
			>
				<Modal.Header closeButton>
					<Modal.Title>{Helper.trans('priceAlert.modalSignUp.header')}</Modal.Title>
					<div className="close-btn"></div>
				</Modal.Header>
				<Modal.Body>
					<div className="title">{Helper.trans(`priceAlert.modalSignUp.${enabledVert}.title`)}</div>
					<div
						className={classnames('sign-up-form', enabledVert, {
							'mt-5': enabledVert === 'hotels',
						})}
					>
						{enabledVert === 'cars' ? (
							<div className="vert-icon">
								<i
									className={classnames({
										'icon-car-7-outline': enabledVert === 'cars',
									})}
								/>
							</div>
						) : (
							''
						)}
						{enabledVert === 'hotels' ? (
							<div className="vert-icon ">
								<i
									className={classnames({
										'icon-hotels': enabledVert === 'hotels',
									})}
								/>
							</div>
						) : (
							''
						)}
						{this.dataInfoMethods[enabledVert] ? this.dataInfoMethods[enabledVert]() : ''}

						<div className="form-group" style={{ position: 'relative' }}>
							<FadeTransition timeout={1003} in={this.state.isInvalidEmail}>
								<div className="tooltip-error">{Helper.trans('validation.priceAlert.email')}</div>
							</FadeTransition>
							<input
								onChange={this.onChangeEmail}
								value={this.props.email}
								autoComplete="email"
								placeholder={Helper.trans('keywords.emailAddress')}
								type="email"
								className="form-control"
							/>
							<FadeTransition timeout={1003} in={this.state.isInvalidPhone}>
								<div className="tooltip-error">{Helper.trans('validation.priceAlert.phone')}</div>
							</FadeTransition>
							{phoneInput}
						</div>
						<div className="form-group">
							<button
								style={{ width: '100%' }}
								onClick={this.doSearch}
								className="btn btn-warning btn-block btn-yellow"
							>
								{Helper.trans('keywords.signUpAndSearch')}
							</button>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Checkbox
						onChange={() => {
							this.setState({
								isCheckedNewsLetter: !this.state.isCheckedNewsLetter,
							});
						}}
						checked={this.state.isCheckedNewsLetter}
						label={Helper.trans('priceAlert.modalSignUp.emailNewsletterSubscription')}
						id="price-alert-news-letter"
					/>
					<br />
					<p className="desc">{Helper.trans('priceAlert.modalSignUp.bottomDescription')}</p>
				</Modal.Footer>
			</Modal>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlertShowModalSignUp: state.base.priceAlertShowModalSignUp,
	email: state.base.priceAlertEmail,
	phone: state.base.priceAlertPhone,
	selectedFromLocation: state.car.selectedFromLocation,
	selectedFromDate: state.car.selectedFromDate,
	selectedFromTime: state.car.selectedFromTime,
	selectedFromLocationFlights: state.flight.selectedFromLocation,
	selectedToLocationFlights: state.flight.selectedToLocation,
	selectedFromDateFlights: state.flight.selectedFromDate,
	selectedToDateFlights: state.flight.selectedToDate,
	selectedTripType: state.flight.selectedTripType,
	hotelDestination: state.hotel.hotelDestination,
	cruiseDestination: state.cruises.cruiseDestination,
	cruiseDepartureDate: state.cruises.departureDate,
	checkInDate: state.hotel.checkInDate,
	checkOutDate: state.hotel.checkOutDate,
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
});
export default connect(mapStateToProps, {
	togglePriceAlertSignUpModal,
	setPriceAlertEmail,
	setPriceAlertPhone,
	doSearchCars,
	doSearchFlights,
	doSearchHotels,
	doSearchCruises,
	setClosedPriceAlertSignUpModal,
})(ModalSignUp);
