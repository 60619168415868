import { default as classNames, default as classnames } from 'classnames';
import React, { ChangeEvent } from 'react';

type CheckboxProps = {
	id: string;
	label?: string;
	checked: boolean;
	disabled?: boolean;
	onChange: (event: ChangeEvent<HTMLInputElement>) => void;
	className?: string;
};

class Checkbox extends React.PureComponent<CheckboxProps> {
	render() {
		const { id, label, checked, disabled, onChange, className } = this.props;
		return (
			<div className={classNames('form-check custom-checkbox position-relative', className)}>
				<label htmlFor={id} className="form-check-label">
					<input
						checked={checked}
						name={id}
						id={id}
						disabled={disabled ?? false}
						// @ts-ignore
						onChange={onChange ? onChange : null}
						type="checkbox"
						className="form-check-input"
						value=""
					/>
					<span
						className={classnames('checkmark', {
							disabled,
						})}
					/>{' '}
					{label}
				</label>
			</div>
		);
	}
}

export default Checkbox;
