import { remove } from 'remove-accents';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import Helper from '../app/modules/Helper';
import store from '../store';
import { getFbClientId } from '../utils/cookies';
import {
	emitGMTEvent,
	hideAndShowErrors,
	sendDataToCompare,
	sendDataToPassthruForm,
	setTicketData,
	setTooltipError,
	submitForm,
	togglePriceAlertSignUpModal,
	toggleProgressLoading,
} from './baseActions';
import { saveFrontFormDataToSessionStorage } from './baseActions.utils';
import { getCarLocation } from './carActions';
import { setErrorForPriceAlertEmail, setErrorForPriceAlertPhone } from './error-checks';
import {
	HOTEL_ADD_ROOM,
	HOTEL_SET_CHECK_IN_DATE,
	HOTEL_SET_CHECK_OUT_DATE,
	HOTEL_SET_DESTINATION,
	HOTEL_SET_ROOM_TYPE,
	HOTEL_UPDATE_ROOMS,
} from './types';

export const selectHotelDestination = (destination) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_DESTINATION,
			payload: destination,
		});
		const errors = { ...store.getState().base.tooltipErrors };
		delete errors['hotel-destination'];
		store.dispatch(setTooltipError(errors));
	});

export const setCheckInDate = (date) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_CHECK_IN_DATE,
			payload: date,
		});
	});

export const setCheckOutDate = (date) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_CHECK_OUT_DATE,
			payload: date,
		});
	});

export const setRoomType = (roomType) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_SET_ROOM_TYPE,
			payload: roomType,
		});
	});

export const addRoom = () =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_ADD_ROOM,
			payload: {
				adults: 1,
				children: 0,
			},
		});
	});

export const updateRooms = (rooms) =>
	catchErrorSync((dispatch) => {
		dispatch({
			type: HOTEL_UPDATE_ROOMS,
			payload: rooms,
		});
	});

export const doSearchHotels = (compare) =>
	catchErrorSync(() => {
		compare = compare || false;
		const hotelState = store.getState().hotel;
		const errors = {};

		if (Helper.isEmpty(hotelState.hotelDestination)) {
			errors['hotel-destination'] = Helper.trans('validation.hotels.location');
		}

		// TODO: insert error checks here
		setErrorForPriceAlertEmail(errors);
		setErrorForPriceAlertPhone(errors);

		store.dispatch(setTooltipError(errors));
		const baseState = store.getState().base;
		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			Helper.isDesktop()
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}
		if (Helper.isEmpty(errors)) {
			store.dispatch(emitGMTEvent(store.getState().base.enabledVert));
			const parsed = Helper.parseQueryString(window.location.search, true);

			let preventOpenFrontTab = false;

			if (Helper.hasProperty(parsed, 'f') && parsed.f === '0') {
				preventOpenFrontTab = true;
			}

			if (Helper.hasProperty(parsed, 'autoredirect') && parsed.autoredirect === '1') {
				preventOpenFrontTab = true;
			}

			let openBackTabTimeout = 500;

			if (preventOpenFrontTab) {
				openBackTabTimeout = 100;
			}

			setTimeout(function () {
				saveFrontFormDataToSessionStorage(store.dispatch(getFormData('front')));
				if (compare) {
					store.dispatch(sendDataToCompare(store.dispatch(getFormData('front'))));
				} else {
					if (!preventOpenFrontTab) {
						store.dispatch(submitFormFront());
					}
					setTimeout(function () {
						store.dispatch(toggleProgressLoading(true));
						store.dispatch(submitFormBack());
					}, openBackTabTimeout);
				}
			}, 10);
		} else {
			store.dispatch(hideAndShowErrors());
		}
	});

export const submitFormBack = () =>
	catchErrorSync(() => {
		const form = document.getElementById('redirect-back-form');
		const data = store.dispatch(getFormData('back'));
		store.dispatch(setTicketData(data));
		store.dispatch(submitForm(form, data));
	});

export const submitFormFront = () =>
	catchErrorSync(() => {
		const formData = store.dispatch(getFormData('front'));
		store.dispatch(sendDataToPassthruForm(formData));
	});

export const getFormData = (formType) =>
	catchErrorSync(() => {
		const data = store.getState().hotel;
		const destination = data.hotelDestination;
		const dateFrom = data.checkInDate;
		const dateTo = data.checkOutDate;
		const rooms = data.rooms;
		let locationId = null;
		let locationKey = null;
		let city = '';

		// map destination  for kayak search
		switch (destination.kayakType) {
			case 'ap':
				locationId = destination.iata;
				locationKey = 'airport';
				city = destination.city;
				break;
			case 'city':
				locationId = 'C:' + destination.ptid.substr(1);
				locationKey = 'city';
				city = destination.city;
				break;
			case 'hotel':
				locationId = 'H:' + destination.ptid.substr(1);
				locationKey = 'hotel';
				city = destination.city;
				break;
			default:
				break;
		}
		// map for prepopulate
		if (destination.kayakType === undefined) {
			switch (destination.type) {
				case 'city':
					locationId = destination.cityID;
					locationKey = 'city';
					city = destination.name;
					break;
				case 'hotels':
					locationId = destination.cityID;
					locationKey = 'hotel';
					city = destination.city;
					break;
				default: // map airport
					locationId = destination.iata;
					locationKey = 'airport';
					break;
			}
		}

		const adults = rooms
			.map((item) => {
				return item.adults;
			})
			.join('|');
		const children = rooms
			.map((item) => {
				return item.children;
			})
			.join('|');

		// RENTAL CONTRACT DURATION
		const date_from = new Date(dateFrom);
		const date_to = new Date(dateTo);
		const diffTime = Math.abs(date_to - date_from);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		const dta = Math.abs(date_from - Date.now());
		const dtaDiff = Math.ceil(dta / (1000 * 60 * 60 * 24));
		const vert = store.getState().base.enabledVert;

		const fbp = getFbClientId();

		const formData = {
			tab: formType,
			vert,
			'location-name': remove(getCarLocation(data.hotelDestination)),
			'hotel-country': remove(destination.country),
			'state-code': destination.stateCode,
			'country-code': destination.countryCode,
			'check-in-date': Helper.toISODateTimeString(dateFrom),
			'check-out-date': Helper.toISODateTimeString(dateTo),
			'destination-id': locationId,
			'destination-key': locationKey,
			destination: remove(getCarLocation(data.hotelDestination)),
			city,
			hiata: destination.iata,
			rooms: rooms.length,
			phone: store.getState().base.priceAlertPhone,
			_adults: adults,
			_children: children,
			email: store.getState().base.priceAlertEmail,
			los: diffDays,
			dta: dtaDiff,
			hid: destination.clicktripzHotelId,
			...(fbp && { fbp }),
		};

		return formData;
	});

export const prePopulateDataFromQueryString = () =>
	catchErrorSync(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);
		if (Helper.hasProperty(parsed, 'date-from') && parsed['date-from']) {
			let dateFrom = parsed['date-from'];
			if (Helper.isValidDateFormat(dateFrom)) {
				dateFrom = Helper.convertStringDateToDateObject(dateFrom);
				store.dispatch(setCheckInDate(dateFrom));
			}
		}
		if (Helper.hasProperty(parsed, 'date-to') && parsed['date-to']) {
			let dateTo = parsed['date-to'];
			if (Helper.isValidDateFormat(dateTo)) {
				dateTo = Helper.convertStringDateToDateObject(dateTo);
				store.dispatch(setCheckOutDate(dateTo));
			}
		}
	});
