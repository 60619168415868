import Trans from 'app/components/Common/Trans';
import { ItemType } from 'app/modules/Constants';
import classnames from 'classnames';
import React from 'react';

type TabBarSelectProps = {
	items: ItemType[];
	selectedItem?: ItemType;
	onSelect: (item: ItemType) => void;
};
class TabBarSelect extends React.Component<TabBarSelectProps> {
	render() {
		const { onSelect, selectedItem, items } = this.props;
		return (
			<div className="d-xl-none d-flex basic-drop-down-tab-view">
				{items.map((item, key) => {
					return (
						<div
							onClick={() => onSelect(item)}
							key={key}
							className={classnames('item', {
								selected: !!(selectedItem && Object.keys(selectedItem).length && selectedItem.value === item.value),
							})}
						>
							<Trans i18nKey={item.label} />
						</div>
					);
				})}
			</div>
		);
	}
}
export default TabBarSelect;
