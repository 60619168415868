import React, { RefObject } from 'react';
import { DateFormatter, DateRange, DayPicker, DayPickerBase, addToRange } from 'react-day-picker';
import { connect } from 'react-redux';
import { togglePreventEnter } from '../../../actions/baseActions';

import { Locale, addDays, format, isAfter, subDays } from 'date-fns';
import { localeMap } from '../../modules/Constants';
import Helper from '../../modules/Helper';

import '@styles/DatePicker.scss';

type DatePickerProps = {
	lng: string;
	before: Date;
	selected: Date;
	className?: string;
	showErrors?: boolean;
	togglePreventEnter: (toggle: boolean) => void;
	onSelectDate: (day: Date, keyboardNavigation?: boolean) => void;
};

class DatePicker extends React.Component<DatePickerProps, DateRange> {
	calendarRef: RefObject<DayPickerBase>;
	wrapperRef: RefObject<HTMLDivElement>;

	constructor(props: DatePickerProps) {
		super(props);
		this.state = this.getInitialState();
		this.calendarRef = React.createRef();
		this.wrapperRef = React.createRef();
	}

	NU_LOCALE = Helper.isArabicLanguage(this.props.lng) ? 'ar-u-nu-arab' : 'en-u-nu-latn';

	getInitialState(): DateRange {
		return {
			from: undefined,
			to: undefined,
		};
	}

	handleDayClick = (day: Date) => {
		const range = addToRange(day, this.state);
		this.setState(range as DateRange);
	};

	handleResetClick = () => {
		this.setState(this.getInitialState());
	};

	componentDidMount() {
		document.addEventListener('keydown', this.onKeyUp);
		this.getPickerPosition();
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.onKeyUp);
	}

	onKeyUp = (e: KeyboardEvent) => {
		const { selected, before } = this.props;
		if ([32, 37, 38, 39, 40, 13].indexOf(e.keyCode) > -1) {
			e.stopPropagation();
			e.preventDefault();
		}

		switch (e.key) {
			// Up
			case 'ArrowUp':
				const sevenDaysBefore = subDays(selected, 7);
				if (isAfter(sevenDaysBefore, subDays(before, 1))) {
					this.selectDateNavigation(sevenDaysBefore, true);
				}
				break;
			// Down
			case 'ArrowDown':
				const sevenDaysAfter = addDays(selected, 7);
				this.selectDateNavigation(sevenDaysAfter, true);
				break;
			// Left
			case 'ArrowLeft':
				const oneDaysBefore = subDays(selected, 1);
				if (isAfter(oneDaysBefore, subDays(before, 1))) {
					this.selectDateNavigation(oneDaysBefore, true);
				}
				break;
			// Right
			case 'ArrowRight':
				const oneDaysAfter = addDays(selected, 1);
				this.selectDateNavigation(oneDaysAfter, true);
				break;
			// Enter
			case 'Enter':
				this.props.togglePreventEnter(true);
				this.selectDateNavigation(selected, false);
				break;
			default:
				break;
		}
		return false;
	};

	selectDateNavigation = (date: Date, preventClose: boolean) => {
		this.props.onSelectDate(date, preventClose);
		// this.calendarRef.current?.showMonth(date);
	};

	getLocaleFromLanguage = (language: string): Locale => {
		const locale = localeMap[language];
		if (locale) {
			return locale;
		} else {
			return localeMap[language];
		}
	};

	getPickerPosition = () => {
		const wrapperRef = this.wrapperRef.current;
		const compStyles = window.getComputedStyle(wrapperRef as Element);
		const rightOffset = compStyles.getPropertyValue('right');

		if (rightOffset) {
			setTimeout(() => {
				wrapperRef?.setAttribute('style', `--picker-right-offset: ${rightOffset}`);
			}, 10);
		}
	};

	render() {
		const from = this.props.selected;
		const { lng, before, onSelectDate } = this.props;

		const modifiers = {
			start: from,
		};

		const formatCaption: DateFormatter = (date, options) => {
			const m = format(date, 'LLLL', { locale: options?.locale });
			const y = date.getFullYear().toLocaleString(this.NU_LOCALE, { useGrouping: false });

			return `${m} ${y}`;
		};

		const formatWeekdayName: DateFormatter = (date, options) => {
			const m = format(date, 'EEE', { locale: options?.locale });
			return m;
		};

		const isRtl = Helper.isRtlLanguage(lng);

		return (
			<div className="date-picker-range" ref={this.wrapperRef}>
				<DayPicker
					locale={this.getLocaleFromLanguage(lng)}
					defaultMonth={from}
					showOutsideDays={true}
					fromMonth={before}
					onDayClick={(date: Date) => onSelectDate(date)}
					numberOfMonths={1}
					disabled={{ before }}
					selected={from}
					modifiers={modifiers}
					dir={isRtl ? 'rtl' : 'ltr'}
					formatters={{ formatCaption, formatWeekdayName }}
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: any) => ({
	lng: state.base.lng,
});
export default connect(mapStateToProps, { togglePreventEnter })(DatePicker);
