import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Helper from '../../../modules/Helper';

export interface IItemProps {
	keyword: string;
	type: string;
	enabledVert: string;
	focused?: boolean;
	firstItem?: boolean;
	item: IAirportItem;
	onHover: () => void;
	onHoverClose: () => void;
	onSelect: (selectedItem: IAirportItem) => void;
}

export interface IAirportItem {
	name: string;
	allAirports?: boolean;
	iata?: string;
	type: string;
	city?: string;
	state?: string;
	documentId?: string;
	cityID?: string;
	airportID?: string;
	country: string;
	clicktripzHotelId?: string;
}

const Item: React.FC<IItemProps> = (props) => {
	const { t } = useTranslation();

	const searchKeyword = (value: string) => {
		let keyword = props.keyword;
		keyword = keyword.replace('(', '');
		keyword = keyword.replace(')', '');
		keyword = keyword.replace(' ', ',');
		// @ts-ignore
		keyword = keyword.split(',');
		// @ts-ignore
		keyword = keyword.filter((k) => {
			return k.length > 1;
		});
		for (let i = 0; i < keyword.length; i++) {
			let search = keyword[i];
			search = Helper.capitalize(search.trim());
			if (value !== undefined) {
				value = Helper.replaceAll(value, search, '<span class="highlight">' + search + '</span>');
			}
		}
		return value;
	};

	let { name, iata, city, country, state } = props.item;
	const enabledVert = props.enabledVert;
	if (props.firstItem) {
		name = t('keywords.allAirports');
	} else {
		name = searchKeyword(name);
	}
	if (iata) {
		name += ' (' + iata + ')';
	}
	let isCity = false;
	if (enabledVert === 'cars' && !iata) {
		isCity = true;
	}
	let type = props.type;
	if (isCity) {
		type = 'city';
	}

	const info = [];
	if (city) {
		info.push(searchKeyword(city));
	}
	if (state) {
		info.push(searchKeyword(state));
	}
	country = searchKeyword(country);
	info.push(country);

	return (
		<div
			onClick={() => props.onSelect(props.item)}
			className={classnames('item ' + type, {
				focused: props.focused,
			})}
			onMouseOver={props.onHover}
			onMouseOut={props.onHoverClose}
		>
			<div>
				<div
					dangerouslySetInnerHTML={{
						__html: name,
					}}
					className="name"
				/>
				<div
					dangerouslySetInnerHTML={{
						__html: info.join(', '),
					}}
					className="info"
				/>
			</div>
		</div>
	);
};

export default Item;
