import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import classnames from 'classnames';
import React from 'react';
import CustomScroll from 'react-custom-scroll';
import { connect } from 'react-redux';
import { openInput, setTooltipError, togglePreventEnter, togglePreventFocus } from '../../../actions/baseActions';
import { suggestAirportsElasticConfig, suggestCitiesElasticConfig } from '../../api/cars';
import ApiInterface from '../../modules/ApiInterface';
import Helper from '../../modules/Helper';
import Item, { IAirportItem } from './AirportSelect/Item';
import AirportSelectSearchInput from './AirportSelectSearchInput';
import FadeTransition from './FadeTransition';
import OvalSpinner from './Loaders/OvalSpinner';

import { HIGHLIGHT_CLICKED } from 'app/modules/PsEvents';
import 'react-custom-scroll/dist/customScroll.css';

type AirportSelectProps = {
	openedInput?: string;
	name: string;
	enabledVert: string;
	placeholder?: string;
	icon?: string;
	lng: string;
	countryCode?: string;
	i18nValidationDropDownKey?: string;
	searchForZipCode?: boolean;
	preventSetInputBoxClassName?: boolean;
	selected?: IAirportItem;
	openInput: (name?: string) => void;
	togglePreventEnter: (toggle: boolean) => void;
	setTooltipError: (error: object) => void;
	togglePreventFocus: (toggle: boolean) => void;
	onSelect: (item: IAirportItem | object) => void;
};
type AirportSelectState = {
	keyword: string;
	selected: string;
	list: IAirportItem[];
	cities: IAirportItem[];
	finalItems: IAirportItem[];
	in: boolean;
	enableInput: boolean;
	hovered: boolean;
	focusedIndex: number;
	isLoading: boolean;
};

class AirportSelect extends React.Component<AirportSelectProps, AirportSelectState> {
	constructor(props: AirportSelectProps) {
		super(props);
		this.state = {
			keyword: '',
			selected: '',
			list: [],
			cities: [],
			finalItems: [],
			in: false,
			enableInput: false,
			focusedIndex: -1,
			hovered: false,
			isLoading: false,
		};
	}

	componentDidMount() {
		const { lng } = this.props;
		if (this.props.selected) {
			this.setState({
				selected: this.getSelected(this.props.selected),
			});
		}

		document.addEventListener('keydown', this.handleKeyboardEvent);

		// THIS WILL BE TRIGGERED ONLY IN CASE OF JAPANESE LANGUAGE
		if (lng === 'jp') {
			PubSub.subscribe(HIGHLIGHT_CLICKED, () => this.onSelect(this.state.finalItems[0]));
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyboardEvent);
		PubSub.unsubscribe(HIGHLIGHT_CLICKED);
	}

	handleKeyboardEvent = (e: KeyboardEvent) => {
		const isOpenedInput = this.isOpenedInput();
		if (!isOpenedInput) {
			return;
		}
		switch (e.key) {
			case 'Shift':
				if (!e.shiftKey) {
					this.selectFocusedIndex(true);
				}
				break;
			case 'Enter':
				e.preventDefault();
				this.enterEvent();
				break;
			case 'ArrowUp':
				e.preventDefault();
				this.pageUpEvent();
				break;
			case 'ArrowDown':
				e.preventDefault();
				this.pageDownEvent();
				break;
			default:
				break;
		}
	};

	enterEvent = () => {
		const { lng } = this.props;
		const { finalItems, focusedIndex } = this.state;

		// selecting the first item in the list for Japanese language users
		if (lng === 'jp') {
			this.onSelect(finalItems[0]);
		}

		if (focusedIndex === -1) {
			return;
		}
		this.props.togglePreventEnter(true);
		this.onSelect(finalItems[focusedIndex]);
	};

	pageUpEvent = () => {
		const { focusedIndex } = this.state;

		if (focusedIndex === -1) {
			return;
		}
		this.setState(
			{
				focusedIndex: focusedIndex - 1,
			},
			() => {}
		);
	};
	pageDownEvent = () => {
		const { finalItems, focusedIndex } = this.state;
		if (finalItems.length === 0) {
			return;
		}
		if (focusedIndex === this.state.finalItems.length - 1) {
			return;
		}
		this.setState(
			{
				focusedIndex: focusedIndex + 1,
			},
			() => {}
		);
	};

	onChange = (e: string) => {
		this.setState(
			{
				keyword: e,
			},
			() => {
				this.fetchAirports();
			}
		);
	};

	fetchAirports = () => {
		const { keyword } = this.state;
		const parsed = Helper.parseQueryString(window.location.search, true);

		if (keyword === '') {
			this.setState({
				list: [],
				cities: [],
				finalItems: [],
			});
			this.props.openInput();
			// @ts-ignore
			this.onSelect({});
		}
		const excludedLanguages = new Set(['jp']);
		if (keyword === '' || (!excludedLanguages.has(this.props.lng.toLowerCase()) && keyword.length < 3)) {
			return this.setState({
				list: [],
				in: false,
			});
		}

		this.props.openInput(this.props.name);
		const enabledVert = this.props.enabledVert;

		this.setState(
			{
				isLoading: true,
			},
			() => {
				if (enabledVert === 'hotels' && parsed?.f === 'cz' && parsed.hid) {
					this.suggestHotelsElastic(keyword);
					return;
				}

				this.suggestKayak(keyword);
			}
		);
	};

	getPlaceIfKeywordIsZipCode = async (zipCode: string): Promise<string> => {
		const isIntegerNumber = /^\d+$/.test(zipCode);
		if (!isIntegerNumber) {
			return zipCode;
		}

		const request = suggestCitiesElasticConfig(zipCode, this.props.lng);

		try {
			const response = await axios(request);
			const [city] = response.data.results;
			const convertedCity = Helper.convertElasticCityResultItemToAirportItem(city);

			if (!city) {
				console.log('NO RESPONSE BY ZIPCODE :(');
				return zipCode;
			}

			return [convertedCity.name, convertedCity.state, convertedCity.country].filter((e) => e).join(', ');
		} catch (e) {
			console.log('ERROR BY ZIPCODE :(', e);
			return zipCode;
		}
	};

	suggestKayak = async (keyword: string) => {
		if (['cars'].includes(this.props.enabledVert)) {
			keyword = await this.getPlaceIfKeywordIsZipCode(keyword);
		}

		const countryLanguageMap: any = {
			ar: 'SA',
			he: 'IL',
		};

		const languageMap: any = {
			ar: 'SA',
			he: 'iw',
		};

		const { lng } = this.props;

		const baseUrl = 'https://www.kayak.com/mvm/smartyv2/search';
		const countryCode = countryLanguageMap[lng] || lng.toUpperCase();
		const langCode = languageMap[lng] || lng;

		const vertURLs = {
			flights: `${baseUrl}?f=j&s=13&v=v1&lc=${langCode}&lc_cc=${countryCode}&where=`,
			cars: `${baseUrl}?f=j&s=81&v=v1&lc=${langCode}&lc_cc=${countryCode}&where=`,
			hotels: `${baseUrl}?f=j&s=50&v=v1&lc=${langCode}&lc_cc=${countryCode}&where=`,
		};

		const config: AxiosRequestConfig = {
			method: 'get',
			// @ts-ignore
			url: vertURLs[this.props.enabledVert] + keyword,
		};

		axios(config)
			.then((response) => {
				const resultItems = response.data;
				let cities = [];
				if (['cars', 'hotels'].includes(this.props.enabledVert)) {
					cities = resultItems
						.filter(
							(item: { loctype: string }) =>
								item.loctype === 'city' || item.loctype === 'addr' || item.loctype === 'hotel'
						)
						.map((item: any) => {
							return {
								country: item.country,
								name: item.name,
								city: item.cityonly,
								state: item.region,
								type: 'city',
								loctype: item.loctype,
								smartyDisplay: item.smartyDisplay,
								kayakType: item.kayakType,
								cityID: 'C:' + item.id,
								ptid: item.ptid,
								stateCode: item.rc,
								countryCode: item.cc,
							};
						});
				}

				// @ts-ignore
				const airports = resultItems
					.filter((item: { loctype: string }) => item.loctype === 'ap')
					.map((item: any) => {
						return {
							country: item.country,
							name: item.name,
							state: item.region,
							city: item.cityonly,
							iata: item.id,
							type: 'airport',
							kayakType: item.kayakType,
							ptid: item.ptid,
							stateCode: item.rc,
							countryCode: item.cc,
						};
					});
				this.setState(
					{
						cities,
						list: airports,
						in: true,
					},
					() => {
						this.baseCheckItems();
					}
				);
			})
			.catch((error) => {
				const enabledVert = this.props.enabledVert;
				switch (enabledVert) {
					case 'flights':
						this.doSuggestFlightsElastic(keyword);
						break;
					case 'cars':
						this.doSuggestCarsElastic(keyword);
						break;
					case 'hotels':
						this.doSuggestCarsElastic(keyword);
					default:
						break;
				}
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	doSuggestFlightsElastic = (keyword: string) => {
		const requests = [suggestAirportsElasticConfig(keyword, this.props.lng)];
		this.setState({
			cities: [],
			in: true,
		});

		axios.all(requests.map((item) => axios(item))).then((responses) => {
			let airports = [];
			airports = responses[0].data.results.map((item: any) => {
				return {
					country: item.country.raw,
					city: item.city.raw,
					iata: item.iata.raw,
					name: item.name.raw,
					allAirports: item.all_airports.raw === '1',
					state: item.state.raw,
					documentId: item._meta.id,
				};
			});
			this.setState(
				{
					list: airports,
					in: true,
				},
				() => {
					this.baseCheckItems();
				}
			);
		});
	};
	doSuggestCarsElastic = (keyword: string, withoutAirport = false) => {
		const requests = [suggestCitiesElasticConfig(keyword, this.props.lng)];
		// @ts-ignore
		if (isNaN(keyword) && !withoutAirport) {
			requests.push(suggestAirportsElasticConfig(keyword, this.props.lng));
		} else {
			this.setState({
				list: [],
				in: true,
			});
		}

		axios.all(requests.map((item) => axios(item))).then((responses) => {
			const cities = responses[0].data.results.map((item: any) => {
				return {
					country: item.country.raw,
					name: item.name ? item.name.raw : item.city?.raw,
					state: item.state.raw,
					documentId: item._meta.id,
					cityID: item?.city_id?.raw,
				};
			});
			let airports = [];
			if (responses.length === 2) {
				airports = responses[1].data.results.map((item: any) => {
					return {
						country: item.country.raw,
						city: item.city.raw,
						iata: item.iata.raw,
						name: item.name.raw,
						allAirports: item.all_airports.raw === '1',
						state: item.state.raw,
						documentId: item._meta.id,
						airportID: item?.airport_id?.raw,
					};
				});
			}
			this.setState(
				{
					list: airports,
					cities,
					in: true,
				},
				() => {
					this.baseCheckItems();
				}
			);
		});
	};

	sendClickRequest = (item: IAirportItem) => {
		const { keyword } = this.state;
		const data = {
			query: keyword, // user input
			document_id: item.documentId, // document that user selected  we get is from search endpoint result
			tags: [this.props.enabledVert, 'tpd'],
		};
		const config: AxiosRequestConfig = {
			method: 'post',
			url:
				process.env.NEXT_PUBLIC_ELASITC_BASE_URL +
				'/api/as/v1/engines/' +
				Helper.getEngineName(item.type, this.props.lng) +
				'/click',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + process.env.NEXT_PUBLIC_ELASITC_AUTHORIZATION_TOKEN,
			},
			data: JSON.stringify(data),
		};
		axios(config)
			.then((res) => {})
			.catch((err) => {});
	};

	suggestHotelsElastic = (keyword: string) => {
		const axios = require('axios');
		const data = JSON.stringify({
			query: keyword,
			precision: 6,
			search_fields: {
				name: {},
				city: {},
			},
			result_fields: {
				clicktripz_hotel_id: {
					raw: {},
				},
				city: {
					raw: {},
				},
				name: {
					raw: {},
				},
				country: {
					raw: {},
				},
			},
		});

		const config = {
			method: 'post',
			url: `${process.env.NEXT_PUBLIC_ELASITC_BASE_URL}/api/as/v1/engines/dev-hotels-en/search`,
			headers: {
				Authorization: `Bearer ${process.env.NEXT_PUBLIC_ELASITC_AUTHORIZATION_TOKEN}`,
				'Content-Type': 'application/json',
			},
			data,
		};

		axios(config)
			.then((response: AxiosResponse) => {
				const list: IAirportItem[] = response.data.results.map((item: any) => {
					return {
						type: 'city',
						name: item.name.raw,
						country: item.country.raw,
						city: item.city.raw,
						clicktripzHotelId: item.clicktripz_hotel_id.raw,
					};
				});
				this.setState(
					{
						cities: list,
						in: true,
					},
					() => {
						this.baseCheckItems();
					}
				);
			})
			.catch((error: AxiosError) => {
				console.log(error);
			})
			.finally(() => {
				this.setState({
					isLoading: false,
				});
			});
	};

	suggestAirports = (keyword: string) => {
		ApiInterface.instance
			.suggestAirports({
				q: keyword,
				lng: this.props.lng,
			})
			.then((res: AxiosResponse) => {
				let list = res.data;
				if (list === null) {
					list = [];
				}
				this.setState(
					{
						list,
						in: true,
					},
					() => {
						this.baseCheckItems();
					}
				);
			})
			.catch(() => {
				this.setState({
					list: [],
					in: true,
				});
			});
	};

	suggestCitiesByZipCode = (keyword: string) => {
		ApiInterface.instance
			.searchByZipcode({
				q: keyword,
				lng: this.props.lng,
			})
			.then((res: AxiosResponse) => {
				let list = res.data;
				if (list === null) {
					list = [];
				}
				this.setState(
					{
						cities: list,
						in: true,
					},
					() => {
						this.baseCheckItems();
					}
				);
			})
			.catch(() => {
				this.setState({
					cities: [],
					in: true,
				});
			});
	};

	suggestCities = (keyword: string) => {
		ApiInterface.instance
			.suggestCities({
				q: keyword,
				lng: this.props.lng,
			})
			.then((res: AxiosResponse) => {
				let list = res.data;
				if (list === null) {
					list = [];
				}
				this.setState(
					{
						cities: list,
						in: true,
					},
					() => {
						this.baseCheckItems();
					}
				);
			})
			.catch(() => {
				this.setState({
					cities: [],
					in: true,
				});
			});
	};

	onSelect = (item?: IAirportItem) => {
		if (item === undefined) {
			return;
		}
		this.setState(
			{
				in: false,
				enableInput: false,
			},
			() => {
				this.props.onSelect(item);
			}
		);
	};

	getSelected = (selected: IAirportItem): string => {
		const node: HTMLElement | null = document.getElementById(this.props.name + '-input');
		if (!node) {
			return '';
		}
		const width = node.offsetWidth - 10;
		// @ts-ignore
		return Helper.getSelectedLocation(selected, width, this.props.enabledVert);
	};

	selectIndex = (index: number, preventFocus?: boolean) => {
		preventFocus = preventFocus || false;
		if (preventFocus) {
			this.props.togglePreventFocus(true);
		}
		if (this.state.finalItems.length) {
			this.onSelect(this.state.finalItems[index]);
		}
	};

	selectFirstItem = (preventFocus?: boolean) => {
		this.selectIndex(0, preventFocus);
	};

	selectFocusedIndex = (preventFocus?: boolean) => {
		if (this.state.focusedIndex === -1) {
			return;
		}
		this.selectIndex(this.state.focusedIndex, preventFocus);
	};

	componentDidUpdate(prevProps: AirportSelectProps) {
		const { selected, enabledVert } = this.props;
		const { keyword } = this.state;

		if (selected && selected !== prevProps.selected) {
			if (!Helper.isEmpty(selected)) {
				this.setState({
					selected: this.getSelected(selected),
				});
			} else {
				this.setState({
					selected: '',
				});
			}
		}

		if (
			prevProps.openedInput == null &&
			this.isOpenedInput() &&
			Helper.isEmpty(selected) &&
			enabledVert === 'flights' &&
			keyword
		) {
			this.selectFirstItem();
		}
	}

	checkItems = () => {
		const airports = Helper.chunk(this.state.list, 3);
		const cities = Helper.chunk(this.state.cities, 2);
		let countLoop = airports.length;
		if (cities.length > countLoop) {
			countLoop = cities.length;
		}
		const items: IAirportItem[] = [];
		for (let i = 0; i < countLoop; i++) {
			if (Array.isArray(airports[i])) {
				airports[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'airport' });
				});
			}
			if (Array.isArray(cities[i])) {
				cities[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'city' });
				});
			}
		}
		this.setState({
			finalItems: items,
		});
	};

	baseCheckItems = () => {
		switch (this.props.enabledVert) {
			case 'hotels':
				this.checkHotelItems();
				break;
			default:
				this.checkItems();
				break;
		}
	};

	checkHotelItems = () => {
		const airports = Helper.chunk(this.state.list, 4);
		const cities = Helper.chunk(this.state.cities, 4);
		let countLoop = airports.length;
		if (cities.length > countLoop) {
			countLoop = cities.length;
		}
		const items: IAirportItem[] = [];
		for (let i = 0; i < countLoop; i++) {
			if (Array.isArray(cities[i])) {
				cities[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'city' });
				});
			}
			if (Array.isArray(airports[i])) {
				airports[i].forEach((airport: IAirportItem) => {
					items.push({ ...airport, type: 'airport' });
				});
			}
		}
		this.setState({
			finalItems: items,
		});
	};

	focusInput = () => {
		const input = document.getElementById(this.props.name + '-input') as HTMLInputElement;

		if (input) {
			input.focus({ preventScroll: true });
			input.select();
		}
		// this.props.openInput(this.props.name);
	};

	onHover = () => {
		this.setState({
			hovered: true,
			focusedIndex: -1,
		});
	};

	onHoverClose = () => {
		this.setState({
			hovered: false,
			focusedIndex: -1,
		});
	};

	isOpenedInput = () => {
		return this.props.openedInput === this.props.name;
	};

	render() {
		const { keyword, hovered } = this.state;
		const onSelect = this.onSelect;
		const onHover = this.onHover;
		const onHoverClose = this.onHoverClose;
		const isOpenedInput = this.isOpenedInput();
		const enabledVert = this.props.enabledVert;
		const items = [];
		const { finalItems, focusedIndex } = this.state;

		for (let i = 0; i < finalItems.length; i++) {
			items.push(
				<Item
					focused={i === focusedIndex && !hovered}
					enabledVert={enabledVert}
					firstItem={finalItems[i].allAirports}
					key={i}
					onHover={onHover}
					onHoverClose={onHoverClose}
					onSelect={onSelect}
					keyword={keyword}
					type={finalItems[i].type}
					item={finalItems[i]}
				/>
			);
		}

		return (
			<div
				id={this.props.name}
				className={classnames('airport-select flex-1', {
					'open-input': isOpenedInput,
					'input-box': !this.props.preventSetInputBoxClassName,
				})}
			>
				<div
					onClick={this.focusInput}
					className={classnames('input-control', {
						focused: isOpenedInput,
						filled: !!this.state.selected,
					})}
				>
					<i className={this.props.icon} />
					<AirportSelectSearchInput
						name={this.props.name}
						selected={this.state.selected}
						placeholder={this.props.placeholder}
						onChange={this.onChange}
					/>
					{this.state.isLoading && (
						<OvalSpinner
							height={30}
							width={30}
							color="#049dd9"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
							ariaLabel="oval-loading"
							secondaryColor="#696969"
							strokeWidth={6}
							strokeWidthSecondary={6}
						/>
					)}
				</div>
				<FadeTransition timeout={500} in={Boolean(isOpenedInput && items.length)}>
					<div className="items-container">
						<div className="items-list">
							<div className="items">
								<CustomScroll scrollTo={focusedIndex > 4 ? (focusedIndex - 4) * 60 : 0}>
									<div style={{ maxHeight: '275px' }}>{items}</div>
								</CustomScroll>
							</div>
						</div>
					</div>
				</FadeTransition>
			</div>
		);
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	openedInput: state.base.openedInput,
	enabledVert: state.base.enabledVert,
	lng: state.base.lng,
	countryCode: state.base.countryCode,
});

export default connect(mapStateToProps, {
	openInput,
	setTooltipError,
	togglePreventFocus,
	togglePreventEnter,
})(AirportSelect);
