import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import Helper from "../modules/Helper";

export const suggestCitiesElasticConfig =  (keyword: string, lng:string): AxiosRequestConfig => {
    const data = {
        query: keyword,
        precision: 6,
        search_fields: {
            name: {},
            zip: {},
            country:{},
            state:{},
            state_code:{}
        },
        result_fields: {
            country: {
                raw: {},
            },
            city: {
                raw: {},
            },
            name: {
                raw: {},
            },
            state: {
                raw: {},
            },
            city_id: {
                raw: {},
            },
        },
        sort: [
            {
                _score: "desc",
            },
        ],
    };

    const config: AxiosRequestConfig = {
        method: "post",
        url:
            process.env.NEXT_PUBLIC_ELASITC_BASE_URL +
            "/api/as/v1/engines/" +
            Helper.getEngineName("city",lng)+
            "/search",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + process.env.NEXT_PUBLIC_ELASTIC_AUTHORIZATION_TOKEN_CARS,
        },
        data: JSON.stringify(data),
    };
    return config;
};
export const suggestAirportsElasticConfig = (
    keyword: string,
    lng: string
): AxiosRequestConfig => {
    const data = JSON.stringify({
        query: keyword,
        precision: 4,
        search_fields: {
            city: {},
            iata: {},
            country: {},
            state: {},
            name: {},
        },

        result_fields: {
            country: {
                raw: {},
            },
            city: {
                raw: {},
            },
            name: {
                raw: {},
            },
            iata: {
                raw: {},
            },
            all_airports: {
                raw: {},
            },
            state: {
                raw: {},
            },
            airport_id: {
                raw: {},
            },
        },
    });

    const config: AxiosRequestConfig = {
        method: "post",
        url:
            process.env.NEXT_PUBLIC_ELASITC_BASE_URL +
            "/api/as/v1/engines/" +
            Helper.getEngineName("airport",lng)+
            "/search",
        headers: {
            "Content-Type": "application/json",
            Authorization:
                "Bearer " + process.env.NEXT_PUBLIC_ELASTIC_AUTHORIZATION_TOKEN_CARS,
        },
        data,
    };
    return config;
};
