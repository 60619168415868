import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { openInput } from '../../../actions/baseActions';

type AirportSelectSearchInputProps = {
	onChange: (keyword: string) => void;
	openInput: (name?: string) => void;
	selected: string;
	name: string;
	placeholder?: string;
};
type AirportSelectSearchInputState = {
	keyword: string;
};

class AirportSelectSearchInput extends React.Component<AirportSelectSearchInputProps, AirportSelectSearchInputState> {
	constructor(props: AirportSelectSearchInputProps) {
		super(props);
		this.state = {
			keyword: '',
		};
	}

	click = (ev: React.MouseEvent<HTMLInputElement>) => {
		const el = ev.currentTarget;
		if (document.activeElement !== el && el) {
			setTimeout(() => {
				el.select();
			}, 0);
		}
	};

	onChange = (e: ChangeEvent<HTMLInputElement>) => {
		this.setState(
			{
				keyword: e.target.value,
			},
			() => {
				this.props.onChange(this.state.keyword);
			}
		);
	};

	// @ts-ignore
	// eslint-disable-next-line react/no-deprecated
	componentWillReceiveProps(nextProps: AirportSelectSearchInputProps, nextContext) {
		let oldSelected = this.props.selected;
		let selected = nextProps.selected;
		if (!selected) {
			selected = '';
		}
		if (!oldSelected) {
			oldSelected = '';
		}
		if (selected !== oldSelected) {
			this.setState({
				keyword: selected,
			});
		}
	}

	render() {
		const { placeholder, name } = this.props;
		const keyword = this.state.keyword;
		return (
			<input
				onClick={() => this.props.openInput(name)}
				id={`${name}-input`}
				autoComplete="off"
				data-hj-allow
				onMouseDown={this.click}
				onChange={this.onChange}
				placeholder={placeholder}
				value={keyword !== null ? keyword : ''}
				type="text"
				className="form-control"
			/>
		);
	}
}

export default connect(null, { openInput })(AirportSelectSearchInput);
